import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { PageParams } from '../../../interfaces/Props';
import { Wrapper } from '../../Shared/Wrapper';
import { SectionWrapper } from '../../Shared/Wrapper/SectionWrapper';
import {
  OptionEnum,
  useGetWebinarQuery,
  UserRoleEnum,
} from '../../../graphql/__types';
import { WebinarSummary } from './WebinarSummary';
import { WebinarDetailLoader } from '../WebinarLoader/WebinarDetailLoader';
import { Loader } from '../../Shared/Loaders/Loader';
import { ExclamationIcon } from '@heroicons/react/solid';
import { WebinarAction } from '../WebinarAction';
import { WebinarControl } from './WebinarControl';
import { SectionTitle } from '../../Shared/PageTitle';
import { WebinarForm } from '../WebinarForm';
import { WebinarRegistration } from '../WebinarRegistration';
import { WebinarCertificate } from '../WebinarCertificate';
import { getUser, isLoggedIn } from '../../../services/auth';
import { navigate } from 'gatsby-link';
import { CardWrapper } from '../../Shared/Wrapper/CardWrapper';
import SeoHeader from '../../Shared/SeoHeader';

export function WebinarDetail({ id }: RouteComponentProps & PageParams) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { data } = useGetWebinarQuery({
    variables: {
      getWebinarId: typeof id !== 'undefined' ? id : '0',
      getIsWebinar: OptionEnum.Yes,
    },
    fetchPolicy: 'network-only',
    onCompleted: () => setIsLoaded(true),
    onError: () => {
      setIsError(true);
      setIsLoaded(true);
    },
  });

  return (
    <Wrapper>
      <SeoHeader title="Webinar" />

      <section className="w-full py-5">
        <section className="w-full mb-5 text-right uppercase text-sm">
          <span
            className="hover:text-primary-1 cursor-pointer font-bold"
            onClick={() => navigate('/webinars')}
          >
            ← Go Back to Webinars
          </span>
        </section>

        <section className="divide w-full mx-auto">
          <CardWrapper>
            {isLoaded && isError ? (
              <section className="flex flex-col items-center justify-center">
                <ExclamationIcon className="w-28 h-28 text-primary-1" />
                <h1 className="text-xl font-bold">Network Error</h1>
                <p>
                  The operation could not be completed. Please check you
                  connectivity.
                </p>
              </section>
            ) : (
              <React.Fragment />
            )}

            {!isLoaded && !isError ? (
              <section className="flex flex-col space-y-5 md:space-y-0 md:flex-row divide-y-2 md:divide-y-0 md:divide-x-2">
                <section className="lg:pr-5 w-full md:w-4/12">
                  <WebinarDetailLoader />
                </section>

                <section className="w-full md:w-8/12">
                  <section className="flex flex-col h-full items-center justify-center mt-5 md:mt-0">
                    <Loader />
                  </section>
                </section>
              </section>
            ) : !!id && !!data && !!data.getWebinar ? (
              <section className="flex flex-col space-y-5 md:space-y-0 md:flex-row divide-y-2 md:divide-y-0 md:divide-x-2">
                <section className="lg:pr-5 w-full md:w-4/12">
                  <WebinarSummary id={id} isWebinar={OptionEnum.Yes} />
                </section>

                <section className="w-full md:w-8/12 divide md:pl-8">
                  {isLoggedIn() && getUser().role !== UserRoleEnum.User ? (
                    <WebinarControl id={id} isWebinar={OptionEnum.Yes} />
                  ) : null}

                  <section
                    className={`flex flex-col divide-y-2 ${
                      !isLoggedIn() || getUser().role === UserRoleEnum.User
                        ? 'items-center justify-center h-full'
                        : ''
                    }`}
                  >
                    <WebinarAction id={id} isWebinar={OptionEnum.Yes} />

                    {isLoggedIn() && getUser().role !== UserRoleEnum.User ? (
                      <>
                        <SectionWrapper>
                          <SectionTitle value="Details" />
                          <WebinarForm
                            id={id}
                            isUpdate
                            isWebinar={OptionEnum.Yes}
                          />
                        </SectionWrapper>

                        {data.getWebinar.isAllowRegistration ===
                        OptionEnum.Yes ? (
                          <>
                            <SectionWrapper>
                              <SectionTitle value="Registrations" />
                              <WebinarRegistration
                                id={id}
                                isWebinar={OptionEnum.Yes}
                              />
                            </SectionWrapper>

                            <SectionWrapper>
                              <SectionTitle value="Certificate" />
                              <WebinarCertificate
                                id={id}
                                isWebinar={OptionEnum.Yes}
                              />
                            </SectionWrapper>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </section>
                </section>
              </section>
            ) : !isError ? (
              <section className="flex flex-col items-center justify-center">
                <ExclamationIcon className="w-28 h-28 text-primary-1" />
                <h1 className="text-xl font-bold">
                  The operation could not be completed
                </h1>
                <p>An unknown error occured</p>
              </section>
            ) : (
              <React.Fragment />
            )}
          </CardWrapper>
        </section>
      </section>
    </Wrapper>
  );
}
