import React, { Fragment, useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { RouteComponentProps } from '@reach/router';
import SeoHeader from '../Shared/SeoHeader';
import { Wrapper } from '../Shared/Wrapper';
import { WebinarList } from './WebinarList';
import { PageHeader } from '../Shared/PageHeader';
import {
  OptionEnum,
  useGetWebinarStatsQuery,
  WebinarStatusEnum,
} from '../../graphql/__types';
import { TabItem } from '../Shared/TabItem';
import { WebinarLoader } from './WebinarLoader';

export function Webinar(_: RouteComponentProps) {
  const { data, loading } = useGetWebinarStatsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <Wrapper>
      <SeoHeader title="Upcoming Webinars" />

      <section className="flex flex-col w-full py-5">
        <PageHeader title="Webinars" createPath="/webinars/create" />

        {loading ? (
          <WebinarLoader />
        ) : !!data && data.getWebinarStats.totalCount > 0 ? (
          <Tab.Group>
            <Tab.List as="section" className="flex flex-row space-x-2 my-5">
              {!!data && data.getWebinarStats.liveCount > 0 ? (
                <Tab>
                  {({ selected }) => (
                    <TabItem selected={selected}>
                      <section className="indicator">
                        <div className="indicator-item badge bg-red-600 border-0">
                          {data.getWebinarStats.liveCount}
                        </div>
                        <div className="pr-4 grid place-items-center">Live</div>
                      </section>
                    </TabItem>
                  )}
                </Tab>
              ) : null}

              {!!data && data.getWebinarStats.reservedCount > 0 ? (
                <Tab>
                  {({ selected }) => (
                    <TabItem selected={selected}>
                      <span>Reserved</span>
                    </TabItem>
                  )}
                </Tab>
              ) : null}

              {!!data && data.getWebinarStats.joinedCount > 0 ? (
                <Tab>
                  {({ selected }) => (
                    <TabItem selected={selected}>
                      <span>Attended</span>
                    </TabItem>
                  )}
                </Tab>
              ) : null}

              {!!data && data.getWebinarStats.recommendedCount > 0 ? (
                <Tab>
                  {({ selected }) => (
                    <TabItem selected={selected}>
                      <span>Recommended</span>
                    </TabItem>
                  )}
                </Tab>
              ) : null}

              <Tab>
                {({ selected }) => (
                  <TabItem selected={selected}>
                    <span>Upcoming</span>
                  </TabItem>
                )}
              </Tab>

              {!!data && data.getWebinarStats.finishedCount > 0 ? (
                <Tab>
                  {({ selected }) => (
                    <TabItem selected={selected}>
                      <span>Finished</span>
                    </TabItem>
                  )}
                </Tab>
              ) : null}
            </Tab.List>

            <Tab.Panels>
              {!!data && data.getWebinarStats.liveCount > 0 ? (
                <Tab.Panel>
                  <WebinarList
                    status={WebinarStatusEnum.Started}
                    location="normal"
                    count={8}
                    isWebinar={OptionEnum.Yes}
                  />
                </Tab.Panel>
              ) : null}

              {!!data && data.getWebinarStats.reservedCount > 0 ? (
                <Tab.Panel>
                  <WebinarList
                    location="normal"
                    count={8}
                    isReserved
                    isWebinar={OptionEnum.Yes}
                  />
                </Tab.Panel>
              ) : null}

              {!!data && data.getWebinarStats.joinedCount > 0 ? (
                <Tab.Panel>
                  <WebinarList
                    location="normal"
                    count={8}
                    isJoined
                    isWebinar={OptionEnum.Yes}
                  />
                </Tab.Panel>
              ) : null}

              {!!data && data.getWebinarStats.recommendedCount > 0 ? (
                <Tab.Panel>
                  <WebinarList
                    location="normal"
                    count={8}
                    isRecommended
                    isWebinar={OptionEnum.Yes}
                  />
                </Tab.Panel>
              ) : null}

              <Tab.Panel>
                <WebinarList
                  status={WebinarStatusEnum.Waiting}
                  location="normal"
                  count={8}
                  isWebinar={OptionEnum.Yes}
                />
              </Tab.Panel>

              {!!data && data.getWebinarStats.finishedCount > 0 ? (
                <Tab.Panel>
                  <WebinarList
                    status={WebinarStatusEnum.Finished}
                    location="normal"
                    count={8}
                    isWebinar={OptionEnum.Yes}
                  />
                </Tab.Panel>
              ) : null}
            </Tab.Panels>
          </Tab.Group>
        ) : (
          <section className="text-center text-lg uppercase">
            No webinars yet.
          </section>
        )}
      </section>
    </Wrapper>
  );
}
