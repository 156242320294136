import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Wrapper } from '../Shared/Wrapper';
import SeoHeader from '../Shared/SeoHeader';
import { PageTitle } from '../Shared/PageTitle';
import { WebinarForm } from './WebinarForm';
import { OptionEnum } from '../../graphql/__types';

export function WebinarCreate(_: RouteComponentProps) {
  return (
    <Wrapper>
      <SeoHeader title={'Create Webinar'} />

      <section className="w-full py-5">
        <PageTitle value="Create Webinar" />

        <section className="mt-5">
          <section className="divide bg-white border border-gray-100 shadow-md rounded-lg px-4 py-12 md:px-8 w-full md:w-10/12 mx-auto">
            <WebinarForm isWebinar={OptionEnum.Yes} />
          </section>
        </section>
      </section>
    </Wrapper>
  );
}
