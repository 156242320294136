import React from 'react';
import { Router } from '@reach/router';
import { PublicLayout } from '../components/Shared/PublicLayout';
import { PrivateRoute } from '../components/Shared/PrivateRoute';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';
import { WebinarCreate } from '../components/Webinar/WebinarCreate';
import { Webinar } from '../components/Webinar';
import { WebinarDetail } from '../components/Webinar/WebinarDetail';

export default function WebinarPage() {
  return (
    <PublicLayout>
      <Router basepath="/webinars">
        <>
          <Webinar path="/" />
          <PrivateRoute
            component={WebinarCreate}
            path="/create"
            roles={['Administrator', 'Organizer']}
          />
          <WebinarDetail path="/:id" />
        </>
        <NotFoundRedirect path="*" />
      </Router>
    </PublicLayout>
  );
}
